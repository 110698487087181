









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CustomButton from '@/infrastructure/ui/button.vue'

@Component<DeleteOnConfirmation>({
    components: {
        CustomButton
    }
})
export default class DeleteOnConfirmation extends Vue {
    @Prop({ required: true }) id!: string
    @Prop({ required: true }) name!: string
    @Prop({ required: true }) pending!: boolean

    show = false

    @Watch('pending')
    onPendingChange(newValue: boolean, oldValue: boolean): void {
        if (!newValue && oldValue) {
            this.show = false
        }
    }

    onConfirmation(): void {
        this.$emit('deleteBoulder', this.id)
    }
}
