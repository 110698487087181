import { SectorId } from '@/domain/sector/sector-id'
import { sectorImages } from '@/components/image-loader'

export type Sector = {
    id: SectorId
    name: string
    src: string
    active: boolean
}

interface ActiveSectors {
    types: SectorId[]

    textById: (value: SectorId) => string

    items: { value: string; text: string }[]
}

export const activeSectors: ActiveSectors = {
    textById(id: SectorId): string {
        return sectorImages.find((image) => image.id === id.asString())?.name || ''
    },
    types: sectorImages.map((sector) => SectorId.fromString(sector.id)),
    items: sectorImages.map((sector) => ({ value: sector.id, text: sector.name }))
}
