






































import { Component, Vue } from 'vue-property-decorator'
import { ListBoulder } from '@/domain/boulder/ListBoulder'
import { showSnackbar, SNACKBAR } from '@/infrastructure/EventBus/global-actions/showSnackbar'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/infrastructure/store/user'
import { BoulderStore } from '@/infrastructure/store/boulders'
import { consume, PATH, provide } from '@/utils/provide-consum-decorator'
import { IUserStore } from '@/infrastructure/store/user/types'
import { IBoulderStore } from '@/infrastructure/store/boulders/types'
import { FilterStore } from '@/infrastructure/store/filter'
import { BoulderFilter, IFilterStore } from '@/infrastructure/store/filter/types'
import { FilteredList } from '@/application/FilteredList'
import { IListComponent } from '@/views/boulder/list/types'
import { BoulderId } from '@/domain/boulder/Id'

import LoadingCircle from '@/components/LoadingCircle.vue'
import BoulderList from '@/components/boulder/list/List.vue'
import FilterList from '@/components/boulder/filter/filter.vue'
import store from '@/infrastructure/store'
import Layout from '@/components/layout.vue'
import { ControlStore } from '@/infrastructure/store/control'
import { IControlStore } from '@/infrastructure/store/control/types'

@Component({ components: { Layout, LoadingCircle, BoulderList, FilterList } })
@provide({
    [PATH.USER_API]: () => {
        return getModule(UserStore, store)
    },
    [PATH.BOULDER_API]: () => {
        return getModule(BoulderStore, store)
    },
    [PATH.FILTER_API]: () => {
        return getModule(FilterStore, store)
    },
    [PATH.CONTROL_API]: () => {
        return getModule(ControlStore, store)
    }
})
export default class List extends Vue implements IListComponent {
    openFilterSelect = false
    loading = false

    @consume(PATH.USER_API) userStore!: IUserStore
    @consume(PATH.BOULDER_API) boulderStore!: IBoulderStore
    @consume(PATH.FILTER_API) filterStore!: IFilterStore
    @consume(PATH.CONTROL_API) controlStore!: IControlStore

    async mounted(): Promise<void> {
        try {
            this.loading = true
            await this.boulderStore.updateBoulders()
        } catch (e) {
            console.warn(e)
            showSnackbar({
                type: SNACKBAR.ERROR,
                message: 'Boulder konnten nicht geladen werden.'
            })
        } finally {
            this.loading = false
        }
    }

    get listBoulders(): ListBoulder[] {
        if (!this.boulderStore) {
            return []
        }
        const boulders = this.boulderStore.all
        const { bookmarks, topped } = this.userStore.userSpecificBoulderData

        const listed: ListBoulder[] = boulders.map((boulder) => {
            const id = boulder.id.asString()

            return {
                ...boulder,
                bookmarked: bookmarks.includes(id),
                topped: topped.includes(id)
            }
        })
        const list = FilteredList.fromItems(listed).withFilters(this.filterStore.filters)

        return list.filtered()
    }

    async onGoToDetailView(id: BoulderId): Promise<void> {
        await this.boulderStore.setCurrentFilteredListIds(this.listBoulders.map((boulder) => boulder.id.asString()))

        await this.$router.push({
            name: 'boulder-detail',
            params: { id: id.asString() }
        })
    }

    async onUpdateFilter(filter: BoulderFilter): Promise<void> {
        if (filter.values().length < 1) {
            await this.filterStore.removeFilter(filter)

            return
        }
        await this.filterStore.addFilter(filter)
    }

    async handleClick(): Promise<void> {
        await this.$router.push({ name: 'boulder-create' })
    }

    onToggle(): void {
        this.openFilterSelect = !this.openFilterSelect
    }

    async onDeleteBoulder(id: string): Promise<void> {
        try {
            this.loading = true
            await this.boulderStore.deleteBoulder(BoulderId.fromString(id))
        } catch (e) {
            console.warn(e.response)
            showSnackbar({
                type: SNACKBAR.ERROR,
                message: e.response?.data?.message || 'Das löschen ist fehlgeschlagen'
            })
        } finally {
            this.loading = false
        }
    }
}
