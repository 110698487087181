export function cloneObjectMap<K, V>(mapIn: Map<K, V>): Map<K, V> {
    const mapCloned: Map<K, V> = new Map<K, V>()

    mapIn.forEach((object: V, key: K) => {
        // mapCloned.set(key, { ...object })
        // we use class instances rather than objects
        mapCloned.set(key, object)
    })

    return mapCloned
}
