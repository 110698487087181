






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ListBoulder } from '@/domain/boulder/ListBoulder'
import GradeAvatar from '@/components/boulder/list/GradeAvatar.vue'
import { categoryText, CategoryType } from '@/domain/boulder/categories'
import { BoulderId } from '@/domain/boulder/Id'
import DeleteOnConfirmation from '@/components/boulder/delete/index.vue'

@Component({
    components: { GradeAvatar, DeleteOnConfirmation }
})
export default class BoulderList extends Vue {
    @Prop({ type: Array, required: true }) boulders!: ListBoulder[]
    @Prop({ type: String, required: true }) userName!: string
    @Prop({ type: Boolean, required: true }) editMode!: boolean
    @Prop({ type: Boolean, required: true }) loading!: boolean

    category(type: CategoryType): string {
        return categoryText[type]
    }

    goTo(id: BoulderId): void {
        this.$emit('go-to', id)
    }

    deletable(createdBy: string): boolean {
        return createdBy === this.userName && this.editMode
    }
}
