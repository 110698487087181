import { Filter } from '@/application/FilteredList/Filter'
import { Id } from '@/domain/Id/Id'

export type Filters<T> = Map<keyof T, Filter<T, keyof T>>

export class FilteredList<T> {
    readonly _items: T[]
    readonly _filters: Filters<T>

    constructor(items: T[], filters: Filters<T>) {
        this._items = items
        this._filters = filters
    }

    public static fromItems<I>(items: I[]): FilteredList<I> {
        return new FilteredList<I>(items, new Map())
    }

    items(): T[] {
        return this._items
    }

    withFilters(filters: Map<keyof T, Filter<T, keyof T>>): FilteredList<T> {
        return new FilteredList([...this._items], filters)
    }

    filters(): Filter<T, keyof T>[] {
        const result: Filter<T, keyof T>[] = []
        this._filters.forEach((value) => result.push(value))

        return result
    }

    filtered(): T[] {
        let currentResult = [...this._items]
        this._filters.forEach((filter, type) => {
            currentResult = currentResult.filter((item) => {
                const itemValue = item[type]
                if (itemValue instanceof Id) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return filter.values().includes(itemValue.asString())
                }

                return filter.values().includes(itemValue)
            })
        })

        return currentResult
    }
}
