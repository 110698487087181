export class Filter<T, K extends keyof T> {
    readonly _values: T[K][]
    readonly _type: K

    constructor(type: K, values: T[K][]) {
        this._values = values
        this._type = type
    }

    values(): T[K][] {
        return this._values
    }

    type(): K {
        return this._type
    }
}
