import store from '@/infrastructure/store'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { BoulderFilter, BoulderFilterMap, IFilterStore } from '@/infrastructure/store/filter/types'
import { cloneObjectMap } from '@/utils/cloneObjectMap'

@Module({ dynamic: true, store, name: 'filter', namespaced: true })
export class FilterStore extends VuexModule implements IFilterStore {
    filterMap: BoulderFilterMap = new Map()

    get filters(): BoulderFilterMap {
        return cloneObjectMap(this.filterMap)
    }

    @Mutation
    addFilter(filter: BoulderFilter): void {
        const clone = cloneObjectMap(this.filterMap)
        clone.set(filter.type(), filter)

        this.filterMap = clone
    }

    @Mutation
    removeFilter(filter: BoulderFilter): void {
        const clone = cloneObjectMap(this.filterMap)
        clone.delete(filter.type())

        this.filterMap = clone
    }
}
