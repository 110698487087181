






import { Component, Prop, Vue } from 'vue-property-decorator'
import { gradeShort, GradeType } from '@/domain/boulder/grades'

@Component({})
export default class GradeAvatar extends Vue {
    @Prop({ type: String, required: true }) grade!: GradeType

    get short(): string {
        return gradeShort[this.grade]
    }

    get color(): string {
        return this.grade
    }
}
